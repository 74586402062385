.Story-Owner-Card-section{
    position: relative;
    max-width: 400px;
    min-width: 250px;
    width: 50%;
    max-height: 350px;
    background-color: rgba(50, 50, 50, 0.55);
    text-align: center;
    border-radius: 15px;
    overflow: scroll;
    padding: 10px;
    box-shadow: 0px 0px 10px 5px black ;
    margin: 15px;
}

.Story-Owner-Card-section img{
    width: 75%;
    max-width: 150px;
    min-width: 100px;
    max-height: 100px;
    border: 1px solid white;
    border-radius: 10px;
}