.color-card-container{
    margin-top: 20px;
    box-shadow: 0px  0px 10px 5px white;
}

.color-card-list {
    height: 100vh;
    overflow-y: scroll;
    background-color: rgba(128, 128, 128, 0.351);
    border-right: 2px solid black;
    box-shadow: 3px 0px 8px 0px white;
}
.color-card-view{
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.color-type-section{
    background-color: black;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    border-radius: 15px;
    width: 90%;
    margin: auto;
    color: white;
    cursor: pointer;
}

.color-type-section img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 0.5px solid rgb(0, 0, 0);
    box-shadow: 0px 0px 5px 3px white;

}