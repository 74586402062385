.Pcolor-section-body{
    border-bottom: 1px solid black;
    border-top: 1px solid black;

}
.button-color{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    height: 40px;
    border-radius: 30px; 
    margin-right: 22px;
    margin-bottom: 10px; 
    border: 1px solid white;
    color: white;
}
.button-color p{
    margin-top: 15px;
    margin-left: 5px;
}
.button-color span{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
}