.tabs-size{
    height: 45rem;
    color: white;
    overflow-x: scroll;
}

.nav{
    --bs-nav-link-hover-color:white
}
.nav-link{
    color: red;
}

.hight-image{
    max-height: 45rem;
}