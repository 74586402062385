.Header2{
    margin-top:8px ;
}
.mySwiper-Header2{
    height: 30rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    --swiper-theme-color:red
}
.mySwiper-Header2-image-style{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.swiper-button-prev, .swiper-button-next{
    color: rgb(255, 0, 0);
}

.description a{
    text-decoration: none;
}


@media (min-width : 500px) {
    .mySwiper-Header2{
        height: 40rem;
    }
}