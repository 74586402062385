.header-section{
    background: url('../../asset/layered-steps-haikei.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100vw;
    margin-bottom: 30px;
}
.title-fontSize{
    width: 100%;
    padding: 15px;
    text-align: center;
}
.description{
    padding: 14px;
    width: 89%;
    color: white;
    margin: auto;
}

.swiper-personaliti-sectiopn{
    width: 70%;
    height: 450px;
    max-width: 450px;
    min-width: 300px;
    --swiper-theme-color:red;
}
.swiper-personaliti-sectiopn .swiper-slide{
    background-color: rgba(224, 224, 224, 0.5);
    border: 1px solid black;
    border-radius: 15px;
    text-align: center;
    padding: 15px;
    overflow-y: scroll;
}
.swiper-personaliti-sectiopn .swiper-slide img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
    box-shadow: 0px 0px 10px 10px ;
}


@media screen and (min-width: 965px) {
    .header-section{
        background-size: cover;
    }

}
@media screen and (min-width: 768px) {
    .header-section{
        height: 45rem;
    }
    .header-title{
        height: 450px;
    }
    .description{
        width: 65%;
    }

}
