.Advertising-story-container{
    display: flex;
    position: relative;
    width: 100vw;
    height: 8rem;
    background-color: rgb(28, 28, 28);
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 19px;
    display: flex;
    align-items: center;
    scroll-behavior: smooth;
}
.icon-left{
    position: sticky;
    left: 10px;
    cursor: pointer;
    color: white;
    transition: 0.5s;
    z-index: 1;
}
.icon-right{
    position:sticky;
    right: 10px;
    cursor: pointer;
    color: white;
    transition: 0.5s;
}
.icon-right:hover, .icon-left:hover{
    color: rgb(205, 205, 205);
    transition: 0.5s;
    transform: scale(1.18);    
}

.Pcolor2-style{
    width: 100vw;
    margin-top: 15px;
    margin-bottom: 35px;
    box-shadow: 0px 0px 10px 10px ;
}

.pcolor2-body-styles{
    height: 240px;
    display: flex;
    align-items: center;
    background-color: rgba(211, 211, 211, 0.65);
    overflow-x: scroll;
    margin-top: 30px;
    padding-left: 70px;
    position: relative;
}

.pcolor2-body-styles-span{
    position: absolute;
    left: 10px;
    top: 100px;
}
