.Owner-Color-Card-container{
    width: 50vw;
    min-width: 250px;
    max-width: 350px;
    background-color: rgba(255, 255, 255, 0.505);
    margin: 15px;
    padding: 8px;
    position: relative;
    border: 1px solid black;
    border-radius: 10px;
}

.Owner-Color-Card-container img{
    width: 100%;
    max-width: 250px;
    max-height: 200px;
}