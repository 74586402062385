.card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 270px;
    width: 75vw;
    max-width: 500px;
    border-radius: 24px;
    line-height: 1.6;
    margin: 20px;
    z-index:0 ;
    @media (max-width : 350px) {
        width: 85vw;
    }
  }
  .feedback-icon{
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: black;
    border-radius: 50%;
  }
  
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 36px;
    border-radius: 22px;
    color: #ffffff;
    overflow: hidden;
    background: #381e1eef;
    transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .Date-card-section{
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 13px;
  }

  .answer-owner{
    width: 100%;
    border-top: 2px solid white;
    position: relative;
  }
  .owner-date-card-section{
    position:absolute;
    right: 0px;
    font-size : 13px; ;
  }
