.bg-body-nav{
    background: linear-gradient(to   left ,rgb(50, 50, 50),rgb(255, 0, 0),rgb(50, 50, 50) 90%);
    background-size: 500% 500%;
    animation: AnimateBG 7s ease infinite;
    box-shadow: 0px 0px 10px 6px white;
}

.bg-body-nav a{
  text-decoration: none;
  margin-right: 20px;
  padding: 9px;
}
.favicon-images-style{
    background-color: rgba(255, 255, 255, 0.499);
    width: 85px;
    height: 90%;
    border-radius: 10px;
    margin-left: 10px;
    mix-blend-mode: multiply;
}

#basic-nav-dropdown{
  color: white;
}

.phoneNumber-Link-Style{
  text-decoration: none;
  color: black;
}

@media (max-width:997px) {
  .color-lg-white{
    background: rgb(0, 0, 0);
  }
  .color-lg-white a{
    margin-top: 13px;
  }
}

@keyframes AnimateBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}