.Loginform {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 0.4em;
  background-color: #171717;
  border-radius: 25px;
  transition: 0.4s ease-in-out;
}

.LoginCard {
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 22px;
  transition: all 0.3s;
  min-width: 250px;
  width: 80vw;
  max-width: 500px;

}

.LoginCard2 {
  border-radius: 0;
  transition: all 0.2s;
}

.LoginCard2:hover {
  transform: scale(0.98);
  border-radius: 20px;
}

.LoginCard:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
}

#LoginHeading {
  text-align: center;
  margin: 2em;
  color: rgb(255, 255, 255);
  font-size: 1.2em;
}

.Loginfield {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 25px;
  padding: 0.6em;
  border: none;
  outline: none;
  color: white;
  background-color: #171717;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}

.Login-input-icon {
  height: 1.3em;
  width: 1.3em;
  fill: white;
}

.Login-input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #d3d3d3;
}

.sending-mail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:15px 0px 15px 0px;
}

.sending-mail button{
    width: 60px;
    height: 30px;
    background-color: rgb(50, 50, 50);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    box-shadow: 0px 0px 5px 1px white;
}

.button3 {
  margin-bottom: 3em;
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  background-color: #252525;
  color: white;
}

.button3:hover {
  background-color: red;
  color: white;
}
  